<template>
  <div>
    <button
      v-b-modal="`couponEditModal${data._id}`"
      class="btn btn-info btn-sm"
    >
      <i class="fa fa-edit" /> แก้ไข
    </button>

    <b-modal
      :id="`couponEditModal${data._id}`"
      v-model="modal"
      size="lg"
      title="แก้ไขข้อมูล"
    >
      <b-form>
        <div class="d-flex flex-row align-items-center">
          <b-form-group
            class="mr-1 flex-grow-1"
            label="Code"
            label-for="code"
          >
            <b-form-input
              v-model="form.code"
              disabled
            />
          </b-form-group>
        </div>

        <div class="row">
          <div class="col-2">
            <b-form-group
              label="ประเภท"
              label-for="type_id"
            >
              <b-form-select
                v-model="form.type"
                :options="couponTypes"
              />
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group
              label="สกุลเงิน"
              label-for="currency"
            >
              <b-form-select
                v-model="form.currency"
                :options="currencyTypes"
                :disabled="form.type !== 'MONEY'"
              />
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group
              label="มูลค่า"
              label-for="cashback"
            >
              <b-form-input v-model="form.reward" />
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group
              label="Turn (จำนวนเงิน)"
              label-for="cashback"
            >
              <b-form-input v-model="form.turnover" />
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group
              label="ฝากขั้นต่ำ"
              label-for="min_dep"
            >
              <b-form-input v-model="form.min_dep" />
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group
              label="จำนวน"
              label-for="cashback"
            >
              <b-form-input
                v-model="form.amount"
                type="number"
              />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              label="เริ่ม"
              label-for="start"
            >
              <flat-pickr
                id="start"
                v-model="form.start"
                class="form-control"
              />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              label="หมดอายุ"
              label-for="end"
            >
              <flat-pickr
                id="end"
                v-model="form.end"
                class="form-control"
              />
            </b-form-group>
          </div>
        </div>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="success"
          @click="submit()"
        >
          ยืนยัน
        </b-button>
        <b-button
          variant="danger"
          @click="modal = false"
        >
          ยกเลิก
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    BForm,
    BAlert,
    BSpinner,
    BFormCheckboxGroup,
    BFormCheckbox,
    BListGroupItem,
    BListGroup,
    BBadge,
    BOverlay,
    BIconController,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    BCardBody,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: ['data'],
  emits: ['submit'],
  data: () => ({
    modal: false,
    couponTypes: ['MONEY', 'DIAMOND'],
    currencyTypes: ['THB', 'KRW'],
    form: {
      code: '',
      type: 'MONEY',
      currency: 'THB',
      reward: '',
      turnover: '',
      start: '',
      end: '',
      min_dep: '',
      amount: 1,
    },
  }),
  mounted() {
    this.form.id = this.$props.data.id
    this.form.code = this.$props.data.code
    this.form.type = this.$props.data.type
    this.form.turnover = this.$props.data.turnover
    this.form.start = this.$props.data.start
    this.form.end = this.$props.data.end
    this.form.reward = this.$props.data.reward
    this.form.min_dep = this.$props.data.min_dep
    this.form.amount = this.$props.data.amount
  },
  methods: {
    submit() {
      this.modal = false
      this.$emit('submit', this.form)
    },
    generateRandomString() {
      const length = 10
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      let result = ''
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length)
        result += characters.charAt(randomIndex)
      }
      this.form.code = result
    },
  },
}
</script>
